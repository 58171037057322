import React from "react";
import NavMenu from "../navmenu/NavMenu";
import Background from "../background/Background";
import Footer from "../footer/Footer";
import "../../sass/global.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Layout = ({ children }) => (
  <>
    <Background />
    <NavMenu />
    <Container style={{ marginTop: "63px" }} className="page-content">
      <Row>
        <Col xs={12}>
          <h2 className="text-white text-center font-weight-normal pt-5 mb-5 header-text">
            ARMACOM OÜ on 14 aastat tegutsenud metsandusettevõte
          </h2>
          <Col xs={12}>{children}</Col>
        </Col>
      </Row>
    </Container>
    <Footer />
  </>
);

export default Layout;

import React from 'react';
import { Link } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import TreesSVG from '../../images/Trees.svg';
import './NavMenu.scss';

const NavMenu = () => (
    <Navbar bg="white" expand="lg" className="d-lg-flex align-items-lg-center p-0 fixed-top shadow">
        <Container fluid="lg">
            <Navbar.Brand className="ml-3 ml-lg-0 position-relative">
                <Link to="/">
                    <img src={TreesSVG} alt="ARMACOM logo" height="50px" className="d-inline-block position-absolute" />
                    <h1 className="m-0 ml-4-half ml-lg-5 d-inline-block text-black">ARMACOM</h1>
                </Link>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbar-nav" className="border-0 p-0 mr-3 mr-lg-0 no-focus-outline">
                <div className="menu-button-line my-2" />
                <div className="menu-button-line my-2" />
                <div className="menu-button-line my-2" />
            </Navbar.Toggle>
            <Navbar.Collapse id="navbar-nav">
                <Nav className="ml-auto">
                    <Link
                        className="nav-link text-decoration-none mx-lg-3 py-3 py-lg-0 d-flex justify-content-center align-items-center"
                        to="/"
                        activeClassName="active"
                    >
                        Avaleht
                    </Link>
                    <Link
                        className="nav-link text-decoration-none mx-lg-3 py-3 py-lg-0 d-flex justify-content-center align-items-center"
                        to="/meist"
                        activeClassName="active"
                    >
                        Meist
                    </Link>
                    <Link
                        className="nav-link text-decoration-none mx-lg-3 py-3 py-lg-0 d-flex justify-content-center align-items-center"
                        to="/kontakt"
                        activeClassName="active"
                    >
                        Kontakt
                    </Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
);

export default NavMenu;

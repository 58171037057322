import React from "react";
import "./Footer.scss";

const Footer = () => (
  <footer className="bg-white d-flex justify-content-center align-items-center py-2 mt-4">
    ARMACOM © 2020
  </footer>
);

export default Footer;

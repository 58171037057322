import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './Background.scss';

const bgStyle = {
    position: 'fixed',
    left: '0',
    top: '0',
    zIndex: '-1',
    width: '100vw',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
};

const Background = () => {
    return <StaticImage style={bgStyle} src="../../images/Background.jpg" alt="A forest" />;
};

export default Background;

import React from "react";
import "./ContentTitle.scss";

const ContentTitle = ({ title }) => (
  <div className="d-flex justify-content-center skew">
    <div className="content-title-container">
      <h1 className="text-white reverse-skew p-1 px-4 px-md-5 m-0">{title}</h1>
    </div>
  </div>
);

export default ContentTitle;
